export function getSSRStatus() {
  try {
    if (typeof window === 'undefined') {
      return true;
    }
    return false;
  } catch {
    return true;
  }
}
