import Cookies from 'js-cookie';

import { getSSRStatus } from '@core/Utils/Server/ServerStatus';

export const getClientSideCookieValue = <T>(cookieName?: string, initialValue?: T) => {
  const isSsr: boolean = getSSRStatus();

  // if we on the server just use an initial value
  // passing uk as initial locale
  if (isSsr) return initialValue || 'uk';

  // otherwise get initial cookie value from `Cookies.get`
  return Cookies.get(cookieName ?? document?.cookie) as T;
};
