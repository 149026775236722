// Account

import { asMessages } from '@core/Utils/Intl/AsMessages';

export const scope = 'containers.component.postSignup';

const definition = asMessages(scope, {
  accountSignup: 'Sign up',
  keepTrack: 'Keep track of your dealer enquires by signing up to your heycar account',
  keepTrackSavedSearch:
    'View and update all your saved searches in one place by signing up to your heycar account',
  getRecommendations: 'Get recommendations',
  viewSavedSearch: 'View saved search',
  trackDealerEnquiries: 'Track dealer enquiries',
} as const);

export const postSignupMessages = definition.messages;
export const postSignupDefaultMessages = definition.defaultMessages;
export type PostSignupMessages = typeof definition.defaultMessages;
