import { defineMessages } from '@formatjs/intl';

import { getDefaultMessages } from './GetDefaultMessages';

export const asMessages = <
  Definition extends Record<string, string>,
  Scope extends string,
  Values extends Record<string, unknown>,
>(
  scope: Scope,
  definition: Definition,
  values?: Values,
) => {
  type Keys = keyof Definition;

  type RawMessages = {
    [Key in Keys]: {
      // @ts-expect-error - this works, but TS doesn't like it
      id: `${Scope}.${Key}`;
      defaultMessage: Definition[Key];
      values?: Values;
    };
  };

  const rawMessages = {} as RawMessages;

  for (const key in definition) {
    rawMessages[key] = {
      id: `${scope}.${key}`,
      defaultMessage: definition[key],
      values,
    };
  }

  const definedMessages = defineMessages(rawMessages);
  const defaultMessages = getDefaultMessages<RawMessages>(definedMessages);

  return {
    scope,
    messages: definedMessages,
    defaultMessages,
  };
};
