import { FeaturesEvent } from '../../SpecialFeatureOptins/SpecialFeatureOptinsEvent';
import { legitimateInterestsSchema } from './LegitimateInterestsSchema';

type Props = {
  features: { [key: string]: boolean };
};

export const legitimateInterestsToEvent = ({ features }: Props): FeaturesEvent => {
  const event: FeaturesEvent = {
    schema: legitimateInterestsSchema.ref,
    data: {
      features,
    },
  };

  return event;
};
