import { FeaturesEvent } from './SpecialFeatureOptinsEvent';
import { specialFeatureOptinsSchema } from './SpecialFeatureOptinsSchema';

type Props = {
  features: { [key: string]: boolean };
};

export const specialFeatureOptionsToEvent = ({ features }: Props): FeaturesEvent => {
  const event: FeaturesEvent = {
    schema: specialFeatureOptinsSchema.ref,
    data: {
      features,
    },
  };

  return event;
};
