type MessageObject<T> = { [K in keyof T]: { defaultMessage: string } };

export function getDefaultMessages<T extends Record<any, any>>(messages: MessageObject<T>) {
  type DefaultMessages = { [K in keyof T]: T[K]['defaultMessage'] };

  let defaultMessages = {} as DefaultMessages;
  for (let key in messages) {
    defaultMessages[key as keyof T] = messages[key].defaultMessage;
  }
  return defaultMessages;
}
