'use client';

import { useEffect, useState } from 'react';

import { userIdAtom } from '@core/Atoms/User/UserId.atom';
import { clientLog } from '@core/Utils/Log/ClientLog';
import { ServiceConsumer } from '@mobile-app/Entities/ServiceConsumer.entity';
import { AdTrackingPlugin } from '@snowplow/browser-plugin-ad-tracking';
import { ConsentPlugin } from '@snowplow/browser-plugin-consent';
import { FormTrackingPlugin, enableFormTracking } from '@snowplow/browser-plugin-form-tracking';
import { LinkClickTrackingPlugin } from '@snowplow/browser-plugin-link-click-tracking';
import { PerformanceTimingPlugin } from '@snowplow/browser-plugin-performance-timing';
import { WebVitalsPlugin } from '@snowplow/browser-plugin-web-vitals';

import { newTracker, enableActivityTracking, BrowserTracker } from '@snowplow/browser-tracker';

import { generateSnowplowAppId } from '@tracking/Utils/GenerateSnowplowAppId';
import { Tenant } from '@whitelabel/Entities/Tenants.entity';
import { useSetAtom } from 'jotai';
import Cookies from 'js-cookie';
import { usePathname } from 'next/navigation';

export const SnowplowScript = ({ tenant }: { tenant?: Tenant }) => {
  const pathname = usePathname();
  const setUserId = useSetAtom(userIdAtom);
  const [tracker, setTracker] = useState<BrowserTracker | null>(null);
  const activeServiceConsumer = Cookies.get('activeServiceConsumer') as ServiceConsumer;

  useEffect(() => {
    const collectorUrl =
      `${window.location.origin}` === 'http://localhost:3000'
        ? 'localhost:9090'
        : process.env.NEXT_PUBLIC_SNOWPLOW_COLLECTOR_URL!;

    const tracker = newTracker('sp_i15', collectorUrl, {
      appId: generateSnowplowAppId({ app: activeServiceConsumer || tenant }),
      plugins: [
        LinkClickTrackingPlugin(),
        FormTrackingPlugin(),
        AdTrackingPlugin(),
        WebVitalsPlugin(),
        PerformanceTimingPlugin(),
        ConsentPlugin(),
      ],
      stateStorageStrategy: 'cookie',
    });

    setTracker(tracker);
  }, []);

  useEffect(() => {
    enableActivityTracking({ minimumVisitLength: 30, heartbeatDelay: 10 });
    enableFormTracking();
  }, [pathname]);

  useEffect(() => {
    if (!tracker) return;

    const snowplowUserId = tracker?.getDomainUserId() as unknown as string | null;

    // if we already have a userId then it's likely been resolved
    setUserId(userId => {
      const clientUserId = userId ? userId : snowplowUserId;

      tracker.setUserId(clientUserId);

      clientLog({ data: clientUserId, label: 'Client user ID' });
      return clientUserId;
    });

    clientLog({ data: snowplowUserId, label: 'Snowplow user ID' });
  }, [tracker]);

  return null;
};
