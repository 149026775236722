'use client';

import { FC, useEffect } from 'react';

import { localeAtom } from '@core/Atoms/Locale/Locale.atom';
import { tenantAtom } from '@core/Atoms/Tenant/Tenant.atom';
import { valuationAtom } from '@core/Atoms/Valuation/Valuation.atom';
import { Locale } from '@core/Entities/Locale/Locale.entity';
import { clientLog } from '@core/Utils/Log/ClientLog';
import { experimentsAtom } from '@growthBookExperimentation/Atoms/Experiments.atom';
import { flagsAtom } from '@growthBookExperimentation/Atoms/Flags.atom';
import { AllExperiments } from '@growthBookExperimentation/Entities/Experiments.entities';
import { AllFlags } from '@growthBookExperimentation/Entities/FlagKeys.entities';
import { Tenant } from '@whitelabel/Entities/Tenants.entity';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';
import { usePathname } from 'next/navigation';

import { hasMountedOnPdpAtom } from 'Atoms/App/Pdp/hasMountedOnPdp.atom';
import { hasMountedAtom } from 'Atoms/Mounted/hasMounted.atom';

import { PostSignupMessages } from 'Components/LeadForms/StatusDisplay/PostSignup.messages';
import { postSignupStringsAtom } from 'Components/LeadForms/StatusDisplay/PostSignupMessages.atom';

import '@core/Utils/Log/Log';

type Props = {
  flags: AllFlags;
  locale: Locale;
  tenant?: Tenant | undefined;
  experiments?: AllExperiments;
  postSignupStrings: PostSignupMessages;
};

export const AppLogic: FC<Props> = ({ locale, tenant, flags, experiments = [], ...props }) => {
  const setLocale = useSetAtom(localeAtom);
  const setTenant = useSetAtom(tenantAtom);
  const pathname = usePathname();
  const [hasMountedOnPdp, sethasMountedOnPdp] = useAtom(hasMountedOnPdpAtom);

  useHydrateAtoms(
    [
      [flagsAtom, flags],
      [experimentsAtom, experiments],
      [postSignupStringsAtom, props.postSignupStrings],
    ],
    { dangerouslyForceHydrate: true },
  );

  setLocale(locale);
  setTenant(tenant);

  const [valuation, setValuation] = useAtom(valuationAtom);
  const hasMounted = useAtomValue(hasMountedAtom);

  useEffect(() => {
    if (!hasMounted) return;

    // this is to identify if the user has visited the PDP page for the first time
    if (pathname.includes('/auto/') && !hasMountedOnPdp) {
      sethasMountedOnPdp(true);
    }

    if (valuation === undefined) setValuation(null);
  }, [hasMounted]);

  useEffect(() => {
    clientLog({ label: 'flags', data: flags, color: '#fef8eb' });
    clientLog({ label: 'experiments', data: experiments, color: '#fef8eb' });
  }, [flags, experiments]);

  return null;
};

AppLogic.displayName = 'AppLogic';
