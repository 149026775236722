'use client';

import { ReactElement, useEffect, useState } from 'react';

import { clientLog } from '@core/Utils/Log/ClientLog';

export const AdblockChecker = ({ children }: { children: ReactElement }) => {
  const [hasAdBlock, setHasAdBlock] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      // @ts-expect-error - __tcfapi is a global variable
      if (!window.__tcfapi) return setHasAdBlock(true);

      // @ts-expect-error - __tcfapi is a global variable
      window?.__tcfapi?.('ping', 2, (pingReturn: any) => {
        if (pingReturn.cmpLoaded) return;
        setHasAdBlock(true);

        clientLog({
          label: 'Adblock detected: hiding cookie consent',
          data: pingReturn,
          color: '#fdd',
        });
      });
    }, 3000);
  }, []);

  if (hasAdBlock) return null;

  return children;
};
