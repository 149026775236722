import { consentsToEvent } from '@tracking/Schemas/Quantcast/Purpose/Consents/ConsentsToEvent';
import { legitimateInterestsToEvent } from '@tracking/Schemas/Quantcast/Purpose/LegitimateInterests/LegitimateInterestsToEvent';
import { purposeToEvent } from '@tracking/Schemas/Quantcast/Purpose/PurposeToEvent';
import { specialFeatureOptionsToEvent } from '@tracking/Schemas/Quantcast/SpecialFeatureOptins/SpecialFeatureOptinsToEvent';

export function generateConsentTrackingData({ tcData }: { tcData: any }) {
  const consentDataObj = {
    features: { ...tcData.purpose.consents },
  };

  const legitimateInterestsDataObj = {
    features: {
      ...tcData.purpose.legitimateInterests,
    },
  };

  const specialFeatureDataObj = {
    features: { ...tcData.specialFeatureOptins },
  };

  const purposeDataObj = {
    hasAccepted:
      !!Object.keys(tcData.purpose.legitimateInterests).length &&
      !!Object.keys(tcData.purpose.consents).length,
  };

  const purposeEvent = purposeToEvent(purposeDataObj);
  const consentTracking = consentsToEvent(consentDataObj);
  const legitimateInterestTracking = legitimateInterestsToEvent(legitimateInterestsDataObj);
  const specialFeatureTracking = specialFeatureOptionsToEvent(specialFeatureDataObj);
  const context = [consentTracking, legitimateInterestTracking, specialFeatureTracking];

  return {
    consentEvent: { event: purposeEvent, context },
  };
}
