import { PurposeEvent } from './PurposeEvents';
import { purposeSchema } from './PurposeSchema';

type Props = {
  hasAccepted: boolean;
};

export const purposeToEvent = ({ hasAccepted }: Props): PurposeEvent => {
  const event: PurposeEvent = {
    schema: purposeSchema.ref,
    data: {
      has_accepted: hasAccepted,
    },
  };

  return event;
};
