'use client';

import Script from 'next/script';

export const DriftrockScript = () => {
  return (
    <Script id="driftrock-script" type="text/javascript" strategy="lazyOnload">
      {`var script=document.createElement("script");
      script.type="text/javascript";
      script.async=!0;
      script.src="https://inbound-leads.driftrock.com/driftrock.js";
      document.body.insertAdjacentElement('beforeend', script);
      script.addEventListener("load", function() {
        Driftrock.init({
          api_key: '${process.env.NEXT_PUBLIC_DRIFTROCK_KEY}'
        });
      });`}
    </Script>
  );
};

DriftrockScript.displayName = 'DriftrockScript';
